<template>
	<div class="project-admin-bottom-pay">
		<div class="project-admin-bottom-pay-border" v-show="!showPaySuccess">
			<p class="money">
				<span>需支付</span>
				<big>￥{{ period.need_custody_amount_yuan }}</big>元
				<!-- <span>需支付</span><big>￥{{period.need_custody_amount_yuan}}</big><small>.00</small> 元 -->
			</p>
			<div class="mb-10"></div>
			<p style="margin-bottom:20px">
				<span>费用说明：  </span>
				<span>项目资金</span>
				<small>(¥{{ period.max_salary_amount_yuan }})</small>
				<span>   +   平台佣金({{ taskCommissionRate }})</span>
				<small>(¥{{ period.commission_amount_yuan }})</small>
			</p>
			<el-checkbox v-model="useBalance" v-if="task.pay_channel==1 && wallet.available_plt > 0">
        优先使用余额支付？
				<span class="r" >（可用余额：¥{{wallet.available_plt | moneyFormat }})</span>
			</el-checkbox>
			<el-checkbox v-model="useBalance" v-if="task.pay_channel==2 && wallet.available_bank > 0">
				优先使用余额支付？
				<span class="r" >（可用余额：¥{{wallet.available_bank | moneyFormat }})</span>
			</el-checkbox>
		</div>
		<div class="project-admin-bottom-pay-wechat" v-show="!showPaySuccess">
			<div class="addCount-bottom-radio" v-show="radio!=12">
				<el-radio-group v-model="radio">
					<div class="addCount-bottom-radio1" v-show="task.pay_channel==1">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-alipay"/>
            </svg>
						<div class="mb-10"></div>
						<el-radio :label="3">支付宝</el-radio>
					</div>
					<div class="addCount-bottom-radio1" v-show="task.pay_channel==1">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-a-WeChatPay"/>
            </svg>
						<br />
						<div class="mb-10"></div>
						<el-radio :label="6">微信</el-radio>
					</div>
					<div class="addCount-bottom-radio1" v-show="task.pay_channel==1">
            <svg class="icon font-24" aria-hidden="true">
              <use xlink:href="#icon-UnionPay"/>
            </svg>
						<br />
						<div class="mb-10"></div>
						<el-radio :label="9">线下汇款</el-radio>
					</div>
					<div class="addCount-bottom-radio1" v-show="task.pay_channel==2">
						<img src="@/icons/svg/task/银联24X24.svg" alt="" />
						<br />
						<div class="mb-10"></div>
						<el-radio :label="11">第三方托管银行</el-radio>
					</div>
				</el-radio-group>
			</div>
			<div class="mb-40"></div>

			<div class="project-admin-bottom-pay-button">
				<!-- 支付宝支付 -->
				<div class="project-admin-bottom-pay-button-box flex-sb" v-show="radio == 3">
					<el-button type="primary" class="f-16" @click="custody(2, 'web')">跳转到支付宝 ¥{{ needPayAmount  | moneyFormat }}</el-button>
					<el-button type="primary" class="f-16" :loading="payButtonLoading" @click="custody(2, 'scan')">支付宝扫码 ¥{{ needPayAmount  | moneyFormat }}</el-button>
				</div>
				<!-- 微信支付 -->
				<div class="project-admin-bottom-pay-button-wechat-button" v-show="radio == 6">
					<el-button type="primary" :loading="payButtonLoading" @click="custody(1, 'scan')" class="f-16">微信扫码 ¥{{ needPayAmount | moneyFormat }}</el-button>
				</div>
				<!-- 线下汇款支付 -->
				<div class="project-admin-bottom-pay-button-bank" v-show="radio == 9">
					<p class="f-18">平台收款账户信息</p>
					<div class="mb-20"></div>
					<ul>
						<li>
							<span>户名：</span>
							<span>北京联众智赢科技有限公司</span>
						</li>
						<li>
							<span>账号：</span>
							<span>7510 0180 8097 7877 7</span>
						</li>
						<li>
							<span>开户行：</span>
							<span>中国光大银行股份有限公司北京新源支行</span>
						</li>
					</ul>
					<div class="mb-20"></div>
					<button type="button" @click="custody(5,'offline')" class="f-16">我已线下汇款 ¥{{ needPayAmount | moneyFormat }}</button>
				</div>
				<!-- 第三方托管银行 -->
				<div class="project-admin-bottom-pay-button-bank" v-show="radio == 11 ">

					<p class="f-18">第三方托管银行账户信息</p>
					<div class="mb-20"></div>
					<ul v-if="bank">
						<li>
							<span>开户行：</span>
							<span>{{bank.bank_name}}{{bank.sub_bank}}</span>
						</li>
						<li>
							<span>户名：</span>
							<span>{{bank.name}}</span>
						</li>
						<li>
							<span>账号：</span>
							<span>{{bank.bank_num}}</span>
						</li>
					</ul>
					<p v-else>请联系客服</p>
					<div class="mb-20"></div>
					<button type="button" @click="custody(5,'offline')" class="f-16">我已线下汇款 ¥{{ needPayAmount | moneyFormat }}</button>
				</div>

        <!-- 余额足够支付 -->
        <div class="project-admin-bottom-pay-button-bank" v-show="radio == 12 ">
          <p class="f-18">直接使用余额支付</p>
          <div class="mb-20"></div>
          <button type="button" @click="custody(5,'walletOnly')" class="f-16">确认支付</button>
        </div>

				<div class="mb-20"></div>
				<div class="project-admin-bottom-pay-button-checked">
					<!-- <el-checkbox v-model="payChecked">
						我已经同意阅读
						<b>《资金托管协议》</b>
					</el-checkbox> -->
					<p class="r f-12" v-show="radio == 9">银行卡支付周期为1-3个工作日，请耐心等待，避免重复托管</p>
					<div class="mb-20"></div>
				</div>
			</div>
		</div>

		<div class="project-admin-bottom-pay-success" style="padding-bottom:50px;" v-show="showPaySuccess">
			<div>
				<i class="iconfont icon-success g" style="font-size:80px"></i>
			</div>
			<div style="margin-top:20px;">
				<strong class="g" style="font-size:28px;">资金托管成功</strong>
			</div>
		</div>

		<!--二维码支付弹层-->
		<PayPeriodCustody
			v-if="showQrcodeDialog"
			:div-id="qrcodeImgDivId"
			:qr-code-status="generateQrcodeStatus"
			:pay-dialog-title="payDialogTitle"
			:dialog-visible="showQrcodeDialog"
			:period="period"
			:payment-type="paymentType"
			:payment-way="paymentWay"
			:use-balance="useBalance"
			@closeDialog="closePayDialog"
			@paySuccess="onPaySuccess"
		/>
		<!-- END 二维码支付弹层-->

	</div>
</template>

<script>
import { periodCustodyRecharge } from '@/api/taskuc.js';
import QRCode from 'qrcodejs2';
import PayPeriodCustody from '@/components/PayPeriodCustody';

export default {
	components: {
		PayPeriodCustody
	},
	data() {
		return {
			qrcodeLoading: false, //显示二维吗时候的loading效果
			showQrcodeDialog: false, //是否先二维吗支付的dialog
			generateQrcodeStatus: 0, //0未生成 1生成失败 2失效  9成功
			payDialogTitle: '', //支付弹窗的标题
			payData: {},
			qrcodeImgDivId: 'qrcodeImg',
			queryPaymentStatusTimer: null,
			payButtonLoading: false, //支付按钮加载
			paymentWay: 'scan', //支付方式

			radio:3,
      oldRadio:3,
			payChecked: false,
			showChate: false,
			useBalance: false,
			balanceAmount:0,//账户余额，根据托管方式，呈现不同的
			paymentType: 0,
			needPayAmount: 0,
			needPayAmountYuan: '',
			showPaySuccess:false,
		};
	},
	props: {
		wallet:{
			type: Object,
			default: () => {
				return {};
			}
		},
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
		period: {
			type: Object,
			default: () => {
				return {};
			}
		},
		bank:{
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	computed: {
		taskCommissionRate: function() {
			return this.task.commission_rate * 100 + '%';
		}
	},
	watch: {
		useBalance(val) {
      console.log( 'useBalance.....', val );
			if (val) {
        if( this.task.pay_channel == 1 ){//平台支付
          this.needPayAmount = this.period.need_custody_amount -  this.wallet.available_plt;
        }else{//第三方合作银行
          this.needPayAmount = this.period.need_custody_amount - this.wallet.available_bank;
        }
			} else {
				this.needPayAmount = this.period.need_custody_amount;
			}

      //如果无需要第三方支付，
      if( this.needPayAmount == 0 ){
        this.oldRadio = this.radio;
        this.radio = 12;
      }else{
        this.radio = this.oldRadio;
      }
		}
	},
	mounted() {
    console.log( this.period );
		this.needPayAmount = this.period.need_custody_amount;
		this.qrcodeImgDivId = 'qrcodeImg' + this.period.id;
		this.radio = this.task.pay_channel==2 ? 11 : 3
	},
	created() {
		this.qrcodeImgDivId = 'qrcodeImg' + this.period.id;
	},
	methods: {
		closePayDialog() {
			this.showQrcodeDialog = false;
		},
		async custody(paymentType, paymentWay) {
			this.paymentType = paymentType;
			this.paymentWay = paymentWay;
			this.showQrcodeDialog = true;
		},
		onPaySuccess(){
			this.showQrcodeDialog = false;
			this.showPaySuccess = true;
			this.$emit('paySuccess');
		}
	}
};
</script>

<style lang="less" scoped>

.project-admin-bottom-pay {
	width: 500px;
	margin: 0 auto;
	text-align: center;
	margin-top: 60px;
	img {
		width: 24px;
		height: 24px;
	}
	.project-admin-bottom-pay-border {
		border-bottom: 1px solid #b5b5b5;
		padding-bottom: 40px;
		p {
			span {
				color: #575757;
				font-size: 18px;
			}
			strong {
				font-size: 18px;
			}
			big {
				font-size: 36px;
			}
		}
		.money {
			height: 28px;
			margin: 40px 0 20px 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		/deep/.el-checkbox__label {
			color: #575757;
		}
	}
	.project-admin-bottom-pay-wechat {
		.addCount-bottom-radio {
			display: flex;
			width: 300px;
			margin: 0 auto;
			margin-top: 40px;
			/deep/.el-radio-group {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
			.addCount-bottom-radio1 {
				width: 60px;
        svg {
          width: 24px;
          height: 24px;
        }
			}
		}
		.project-admin-bottom-pay-button {
			.project-admin-bottom-pay-button-box {
				width: 440px;
				button {
					width: 200px;
					background-color: #00a2e6;
				}
			}
			.project-admin-bottom-pay-button-wechat-button {
				button {
					width: 240px;
					background-color: #00a2e6;
				}
			}
			.project-admin-bottom-pay-button-checked {
				b {
					color: #00a2e6;
				}
			}
			.project-admin-bottom-pay-button-bank {
				color: #575757;
				ul {
					li {
						margin-bottom: 10px;
					}
					strong {
						font-size: 12px;
						color: #000;
					}
				}
				button {
					width: 240px;
					height: 36px;
					background-color: #00a2e6;
				}
			}
		}
	}
}
</style>
