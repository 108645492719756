<template>
  <div class="project-administration-bottom-employee">
    <div class="mb-40"></div>
    <p><strong class="mr-10 f-18">本期雇员</strong><span>共5人</span></p>
    <div class="mb-20"></div>
    <div class="project-administration-bottom-employee-tabel">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 870px; font-size: 12px"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{
          'text-align': 'center',
          height: '32px',
          color: '#000',
        }"
        :cell-style="{
          'text-align': 'center',
          height: '32px',
          color: '#474747',
        }"
      >
        <el-table-column type="selection" width="60" label="全选">
        </el-table-column>
        <el-table-column label="姓名" width="60" prop="name"> </el-table-column>
        <el-table-column prop="hireState" label="雇佣状态" width="100">
        </el-table-column>
        <el-table-column prop="money" label="协议报酬" width="100px">
        </el-table-column>
        <el-table-column prop="check" label="验收后报酬" width="100px">
        </el-table-column>
        <el-table-column prop="taskState" label="任务状态" width="100px">
        </el-table-column>
        <el-table-column prop="moneyState" label="薪资状态" width="100px">
        </el-table-column>
        <el-table-column prop="arbitration" width="80px">
          <template slot-scope="scope" slot="header">
            <span :aa="scope">
              仲裁
              <i
                class="iconfont icon-a-question-frontpage r"
                style=" margin-left:5px;'"
              >
              </i>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" width="160px">
          <template slot-scope="{ $index, row }">
            <el-button type="text" @click="handleRelieve($index, row)">
              {{ row.handle.relieve }}
            </el-button>
            <el-button type="text" @click="handleRelieve($index, row)">
              {{ row.handle.pause }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mb-20"></div>
    <div class="project-administration-bottom-employee-drop">
      <el-dropdown @command="handleCommand" placement="top">
        <span class="el-dropdown-link"> 批量操作 </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">解除雇佣</el-dropdown-item>
          <el-dropdown-item command="b">暂停雇佣</el-dropdown-item>
          <el-dropdown-item command="c">重新雇佣</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-pagination layout="prev, pager, next" :total="50"> </el-pagination>
    </div>
    <div class="mb-40"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "刘小宝",
          hireState: "已雇佣",
          money: "600",
          check: "---",
          taskState: "未开始",
          moneyState: "---",
          arbitration: "---",
          handle: {
            relieve: "解除雇佣",
            pause: "暂停雇佣",
          },
        },
        {
          name: "刘小宝",
          hireState: "本期暂停",
          money: "600",
          check: "---",
          taskState: "---",
          moneyState: "---",
          arbitration: "---",
          handle: {
            relieve: "解除雇佣",
            pause: "重新雇佣",
          },
        },
        {
          name: "刘小宝",
          hireState: "已雇佣",
          money: "600",
          check: "---",
          taskState: "未开始",
          moneyState: "---",
          arbitration: "---",
          handle: {
            relieve: "解除雇佣",
            pause: "暂停雇佣",
          },
        },
      ],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCommand(command) {
      this.$message("click on item " + command);
    },
  },
};
</script>

<style lang="less" scoped>
.project-administration-bottom-employee {
  width: 862px;
  margin: 0 auto;
  border-top: 1px solid #b5b5b5;
  .project-administration-bottom-employee-drop {
    display: flex;
    height: 14px;
    justify-content: space-between;
  }
  /deep/.el-pagination button,
  /deep/.el-pagination span:not([class*="suffix"]) {
    height: 14px;
  }
  /deep/.el-pager li {
    height: 14px;
    line-height: 14px;
    /* color: #575757; */
  }
}
/deep/.el-table,
/deep/ .el-table__cell {
  padding: 0 !important;
}
/deep/.el-button--text {
  font-size: 12px;
  color: #575757;
}
/deep/.el-button--text:hover {
  color: #00a2e6;
}
</style>