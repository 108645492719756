var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-administration-bottom-employee"},[_c('div',{staticClass:"mb-40"}),_vm._m(0),_c('div',{staticClass:"mb-20"}),_c('div',{staticClass:"project-administration-bottom-employee-tabel"},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"870px","font-size":"12px"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
        'text-align': 'center',
        height: '32px',
        color: '#000',
      },"cell-style":{
        'text-align': 'center',
        height: '32px',
        color: '#474747',
      }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"60","label":"全选"}}),_c('el-table-column',{attrs:{"label":"姓名","width":"60","prop":"name"}}),_c('el-table-column',{attrs:{"prop":"hireState","label":"雇佣状态","width":"100"}}),_c('el-table-column',{attrs:{"prop":"money","label":"协议报酬","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"check","label":"验收后报酬","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"taskState","label":"任务状态","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"moneyState","label":"薪资状态","width":"100px"}}),_c('el-table-column',{attrs:{"prop":"arbitration","width":"80px"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('span',{attrs:{"aa":scope}},[_vm._v(" 仲裁 "),_c('i',{staticClass:"iconfont icon-a-question-frontpage r",staticStyle:{"margin-left":"5px"}})])]}}])}),_c('el-table-column',{attrs:{"prop":"handle","label":"操作","width":"160px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var $index = ref.$index;
      var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRelieve($index, row)}}},[_vm._v(" "+_vm._s(row.handle.relieve)+" ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRelieve($index, row)}}},[_vm._v(" "+_vm._s(row.handle.pause)+" ")])]}}])})],1)],1),_c('div',{staticClass:"mb-20"}),_c('div',{staticClass:"project-administration-bottom-employee-drop"},[_c('el-dropdown',{attrs:{"placement":"top"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 批量操作 ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"a"}},[_vm._v("解除雇佣")]),_c('el-dropdown-item',{attrs:{"command":"b"}},[_vm._v("暂停雇佣")]),_c('el-dropdown-item',{attrs:{"command":"c"}},[_vm._v("重新雇佣")])],1)],1),_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":50}})],1),_c('div',{staticClass:"mb-40"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',{staticClass:"mr-10 f-18"},[_vm._v("本期雇员")]),_c('span',[_vm._v("共5人")])])}]

export { render, staticRenderFns }