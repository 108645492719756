<template>
  <div>
    <!-- 已发布 -->
    <div class="tasklist-bid">
      <InfoHeader :task="task"></InfoHeader>
      <!--  评论板块    -->
      <div class="task-info-review">
        <div class="person-header" v-show="true">
          <p>
            <strong>
              评价雇员
            </strong>
            <span class="ml-15">请对自由职业者的服务作出评价，您的评价可以帮助平台更好的甄选优质自由职业者。</span>
          </p>
        </div>
        <div class="task-info-reviews-all" v-show="true">
          <div class="task-info-reviews-all-item">
              <span>总雇佣人数</span>
              <p><strong>35</strong><span>人</span></p>
          </div>
          <div class="task-info-reviews-all-item">
            <span>已评论</span>
            <p><strong>5</strong><span>人</span></p>
          </div>
          <div class="task-info-reviews-all-item">
            <span>已评论</span>
            <p><strong>5</strong><span>人</span></p>
            <el-button type="primary" size="mini" style="width: 85px" round class="review-button">去评论</el-button>
          </div>
        </div>
        <!--  评论单雇员    -->
        <div class="task-info-review-first" v-show="true" >
          <div class="task-info-review-first-center">
            <p><strong>雇员：刘小宝</strong></p>
            <el-form>
              <el-form-item label="评分：" prop="startValue">
                <el-rate
                    v-model="reviewForm.startValue"
                    show-text
                    :texts="texts">
                </el-rate>
              </el-form-item>
              <el-form-item label="评论：" prop="reviewFont" v-show="true">
                <br>
                <el-input
                    type="textarea"
                    v-model="reviewForm.reviewFont"
                    placeholder="请具体描述一下任务的工作内容和要求，最多可输入300个字符。"
                    style="width: 860px"
                    show-word-limit
                    maxlength="500"

                    :autosize="{ minRows: 6, maxRows: 10}"
                ></el-input>
              <div class="task-info-review-first-button" style="display: flex;justify-content: flex-end">
                <el-button type="primary" style="width:120px;border-radius: 0; margin-top: 10px; margin-bottom: 10px" size="mini" >提交评论</el-button>
              </div>
              </el-form-item>
              <div class="task-info-review-first-success">
                <p class="f-12 h-57">
                  我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，
                  下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。
                  我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，
                  下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。我很满意刘小宝的工作，下次一定。
                </p>
                <div class="task-info-review-first-success-font" style="text-align: center">
                    <i class="iconfont icon-success g wh-16"></i><span class="h-8d ml-10">评价成功，感谢您的评价。</span>
                </div>
              </div>
            </el-form>
            <li></li>
          </div>
        </div>
        <!--  评论多雇员      -->
        <div class="task-info-review-more">
        <div class="person-header">
          <p>
            <strong>评价详情</strong>
            <span class="f-12 b">返回</span>
          </p>
        </div>
      </div>
      </div>

      <!-- 招标管理 v-show="false"-->
      <div class="tasklist-administration" v-if="showBidBox">
        <div class="tasklist-admininstration-center">
          <div class="person-header">
            <p>
              <strong>招标管理</strong><span class="f-12 h-57">雇佣2人</span>
              <b class="f-12"
                >请点击选择以标记人才，竞标期结束时，系统将自动雇佣已选择的自由职业者。</b
              >
            </p>
          </div>
          <div class="tasklist-admininstration-bottom">
            <el-tabs v-model="tasklistBottom" @tab-click="handleClick">
              <el-tab-pane name="first">
                <big slot="label">全部 &nbsp;<small>0</small></big>
                <!--竞标前  -->
                <div
                  class="tasklist-admininstration-bottom-no-data"
                  v-show="false"
                >
                  <div class="no-data">
                    <p>招标未开始，暂无数据</p>
                  </div>
                </div>
                <!-- 竞标中 -->
                <div class="tasklist-admininstration-bottom-font">
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <!-- <img
                                src="@/icons/svg/task/资深认证16X16.svg"
                                alt=""
                              /> -->
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="tasklist-admininstration-bottom-font1 clear-fix">
                    <div class="tasklist-admininstration-bottom-font1-left">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-left-center
                        "
                      >
                        <div
                          class="
                            tasklist-admininstration-bottom-font1-left-photo
                          "
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photos
                            "
                          >
                            <img src="@/assets/task/头像4 拷贝.jpg" alt="" />
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-left-photo-fonts
                            "
                          >
                            <p>
                              <strong class="f-16">张长生@yonghunichen</strong>
                              <button>在线沟通</button>
                              <svg class="icon mr-10" aria-hidden="true">
                                <use
                                  xlink:href="#icon-certificate-excellent"
                                ></use>
                              </svg>
                              <i class="iconfont icon-a-PersonalIdentity"></i>
                              <i class="iconfont icon-phone"></i>
                              <i
                                class="iconfont icon-like"
                                @click="hideLike"
                                v-show="like == 1"
                              ></i>
                              <i
                                class="iconfont icon-like no"
                                @click="showLike"
                                v-show="like == 2"
                              ></i>
                            </p>
                            <span
                              >这是用户的一句话描述。如我是一名有3-5年经验的产品经理</span
                            >
                          </div>
                        </div>
                        <div
                          class="tasklist-admininstration-bottom-font1-start"
                        >
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-left
                            "
                          >
                            <ul>
                              <li>
                                <strong>入驻时间：</strong><span>30天前</span>
                              </li>
                              <li>
                                <strong>已完成的工作：</strong><span>12单</span>
                              </li>
                              <li>
                                <strong>时薪：</strong
                                ><span style="color: #00a2e6">50元/小时</span>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="
                              tasklist-admininstration-bottom-font1-start-right
                            "
                          >
                            <el-rate
                              v-model="value"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                            >
                            </el-rate
                            ><span>(35个评论)</span>
                          </div>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-info">
                          <p>
                            您好，您好，您好！我可以根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独特的优质内容，我为网站撰写高质量、无语法错误、关键字丰富、
                            谷歌友好和根据您的要求为您提供创意和引人入胜的高质量内容。我精通提供独11111111
                          </p>
                        </div>
                        <div class="tasklist-admininstration-bottom-font1-task">
                          <ul>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                            <li>PHP</li>
                            <li>HTML</li>
                            <li>平面v设计</li>
                            <li>MySQL</li>
                            <li>平面设计</li>
                            <li>MySQL</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="tasklist-admininstration-bottom-font1-right">
                      <div
                        class="
                          tasklist-admininstration-bottom-font1-right-center
                        "
                      >
                        <i class="iconfont icon-selected"></i><br />
                        <strong>已选择</strong>
                        <button class="select">选择</button>
                        <button class="noSelect" v-show="false">
                          取消选择
                        </button>
                      </div>
                    </div>
                  </div>
                     <!-- 分页器 -->
                    <div class="block">
                      <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="1"
                      :page-sizes="[3, 5, 10]"
                      :page-size="3"
                      layout="prev, pager, next,->,total,jumper"
                      :total="10"
                      background
                      >
                      </el-pagination>
                    </div>
                </div>
              </el-tab-pane>
              <el-tab-pane name="second">
                <big slot="label">待选 &nbsp;<small>0</small></big>
                待选</el-tab-pane
              >
              <el-tab-pane name="third">
                <big slot="label">已选择 &nbsp;<small>0</small></big>
                已选择</el-tab-pane
              >
            </el-tabs>
            <div class="mine-service-bottom-header-right">
              <i class="iconfont icon-search"></i>
              <input
                v-model="text"
                type="text"
                name=""
                id=""
                placeholder="请输入关键字搜索"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 项目管理 -->
      <div class="project-administration">
        <div class="person-header">
          <p>
            <strong>项目周期</strong>
          </p>
        </div>
        <div class="project-administration-bottom">
          <!-- 点击移动 -->
          <div class="mb-40"></div>

          <div class="swiper-container" ref="floor1Swiper">
            <!-- -->
            <div class="swiper-wrapper">
			  <div class="swiper-slide" v-for="(item,index) in periods" @click="changeIndex(1,$event)">
                <div class="project-administration-bottom-box1" :class="{hoverBlue:currentIndex==1}">
                  <div class="project-administration-bottom-box1-header" :class="{borderff:currentIndex==1}">
                    <div class="mb-20"></div>
                    <span :class="{hoverff:currentIndex==1}">第1周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait h-57 wh-16" :class="{hoverff:currentIndex==1}"></i
                      ><strong class="f-24 h-57" :class="{hoverff:currentIndex==1}">未开始</strong>
                     <div class="mb-20"></div>
                     </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57" :class="{hoverff:currentIndex==1}">12-6 至 12-12</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57" :class="{hoverff:currentIndex==1}">本期工作即将开始，托管资金</p>
                    <!-- :class="{err:currentIndex==1}" -->
                  </div>
                  <i
                    class="
                      iconfont
                      icon-close
                      wh-12
                      project-administration-bottom-box1-err
                    "
                    @click="deleteDialogVisible = true"
                    ref="err1"
                    @mousemove="MoveErr"
                    @mouseleave="LeaveErr"
                  ></i>
                </div>
              </div>



              <div class="swiper-slide">
                <div class="project-administration-bottom-box1">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第2周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait wh-16 y"></i
                      ><strong class="f-24 y">请托管资金</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">12-13 至 12-19</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">本期工作即将开始，托管资金</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第3周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait g wh-16"></i
                      ><strong class="f-24 g">资金已托管</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">12-20 至 12-26</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">本期工作即将开始，托管资金</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1" @click="work">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第4周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait wh-16 g"></i
                      ><strong class="f-24 g">工作中</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">12-27 至 1-2</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">已托管资金￥10000.00元</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第5周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-tips r wh-16"></i
                      ><strong class="f-24 r">工作暂停</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">1-3 至 1-9</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">已托管本期项目资金</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第6周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait g wh-16"></i
                      ><strong class="f-24 g">已完成</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">1-10 至 1-16</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">已托管本期项目资金</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1" @click="work">
                  <div class="project-administration-bottom-box1-header">
                    <div class="mb-20"></div>
                    <span>第7周</span>
                    <div class="mb-20"></div>
                    <div class="project-administration-bottom-box1-header-img">
                      <i class="mr-10 iconfont icon-wait b wh-16"></i
                      ><strong class="f-24 b">验收期</strong>
                      <div class="mb-20"></div>
                    </div>
                  </div>
                  <div class="project-administration-bottom-box1-footer">
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">1-17 至 1-23</p>
                    <div class="mb-10"></div>
                    <p class="f-12 h-57">请前往发票页申请开具发票</p>
                  </div>
                  <div
                    class="project-administration-bottom-box1-err"
                    @click="deleteDialogVisible = true"
                  >
                    x
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="project-administration-bottom-box1">
                  <div class="project-administration-bottom-box1-center1">
                    <i
                      class="iconfont icon-a-addto"
                      @click="addDialogVisible = true"
                    ></i>
                    <div class="mb-20"></div>
                    <span class="h-57 f-14" @click="addDialogVisible = true"
                      >增加周期</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <CheckTabel v-show="checktab"></CheckTabel>
          <HirerTabel v-show="hiretab"></HirerTabel>
          <WechateDia></WechateDia>
          <!-- 弹出层 是否完成支付 -->
          <el-dialog
            title=""
            :visible.sync="centerDialogVisible"
            width="600px"
            center
          >
            <div class="dialog-center-header">
              <p>
                <strong class="f-18">您是否完成支付？</strong
                ><b class="f-12 b">付款完成前请不要关闭页面</b>
              </p>
              <div class="mb-20"></div>
            </div>
            <div class="mb-20"></div>
            <span slot="footer" class="dialog-footer">
              <p class="f-18">若支付时遇到问题请联系客服：400-6066-100</p>
              <div class="mb-40"></div>
              <button
                class="dialog-button"
                @click="centerDialogVisible = false"
              >
                完成支付
              </button>
              <button
                type="primary"
                class="error"
                @click="centerDialogVisible = false"
              >
                支付失败
              </button>
              <div class="mb-100"></div>
            </span>
          </el-dialog>

          <!-- 删除工作周期 -->
          <el-dialog
            title=""
            :visible.sync="deleteDialogVisible"
            width="600px"
            class="deleteDialog"
            center
          >
            <div class="deleteDialog-center">
              <div class="deleteDialog-center-header">
                <div class="mb-40"></div>
                <p>
                  <strong class="mr-10 f-18">删除工作周期</strong>
                  <span class="r f-12">您确定删除当前工作周期吗？</span>
                </p>
                <div class="mb-20"></div>
              </div>
              <div class="mb-20"></div>
              <div class="deleteDialog-center-header-table">
                <div class="deleteDialog-center-header-table-header">
                  <ul>
                    <li class="deleteDialog-li1">
                      <strong>周期序号</strong>
                    </li>
                    <li class="deleteDialog-li2">
                      <strong>起始时间</strong>
                    </li>
                    <li class="deleteDialog-li2">
                      <strong>完成时间</strong>
                    </li>
                  </ul>
                </div>
                <div class="deleteDialog-center-header-table-bottom">
                  <ul>
                    <li class="deleteDialog-li1">第六周</li>
                    <li class="deleteDialog-li2">2021-12-27</li>
                    <li class="deleteDialog-li2">2022-01-02</li>
                  </ul>
                </div>
              </div>
              <div class="mb-20"></div>
              <div class="deleteDialog-center-checked">
                <el-checkbox
                  ><span
                    >工作周期的变动，涉及到雇佣协议变动，我同意系统自动生成 </span
                  ><b class="f-12 b">补充条款</b></el-checkbox
                >
              </div>
              <div class="mb-40"></div>
              <div class="deleteDialog-center-checked-button flex-sb">
                <button type="button" class="cancel">取消</button>
                <button type="button">确认删除</button>
              </div>
              <div class="mb-40"></div>
            </div>
          </el-dialog>
          <!-- 增加工作周期 -->
          <el-dialog
            title=""
            :visible.sync="addDialogVisible"
            width="600px"
            class="addDialog"
            center
          >
            <div class="deleteDialog-center">
              <div class="deleteDialog-center-header">
                <div class="mb-40"></div>
                <p>
                  <strong class="mr-10 f-18">增加工作周期</strong>
                  <span class="b f-12"
                    >根据项目现有规则，您新增的周期自动认定为自然周</span
                  >
                </p>
                <div class="mb-20"></div>
              </div>
              <div class="mb-20"></div>
              <div class="addDialog-font">
                <i class="iconfont icon-tips wh-16 mr-10 r"></i
                ><span
                  >当前正常雇佣人数为<b>5</b>人，系统会默认将雇员转入新增的周期中。</span
                >
              </div>
              <div class="mb-20"></div>
              <div class="deleteDialog-center-header-table">
                <div class="deleteDialog-center-header-table-header">
                  <ul>
                    <li class="deleteDialog-li1">
                      <strong>周期序号</strong>
                    </li>
                    <li class="deleteDialog-li2">
                      <strong>起始时间</strong>
                    </li>
                    <li class="deleteDialog-li2">
                      <strong>完成时间</strong>
                    </li>
                  </ul>
                </div>
                <div class="deleteDialog-center-header-table-bottom">
                  <ul>
                    <li class="deleteDialog-li1">第六周</li>
                    <li class="deleteDialog-li2">2021-12-27</li>
                    <li class="deleteDialog-li2">2022-01-02</li>
                  </ul>
                </div>
              </div>
              <div class="mb-20"></div>
              <div class="deleteDialog-center-checked">
                <el-checkbox
                  ><span
                    >工作周期的变动，涉及到雇佣协议变动，我同意系统自动生成 </span
                  ><b class="f-12 b">补充条款</b></el-checkbox
                >
              </div>
              <div class="mb-40"></div>
              <div class="deleteDialog-center-checked-button flex-sb">
                <button type="button" class="cancel">取消</button>
                <button type="button">确认增加</button>
              </div>
              <div class="mb-40"></div>
            </div>
          </el-dialog>
        </div>
        <div class="project-administration-bottom-employee">
          <div class="mb-40"></div>
          <p><strong class="mr-10 f-18">本期雇员</strong><span>共5人</span></p>
          <div class="mb-20"></div>
          <div class="project-administration-bottom-employee-tabel">
            <el-table
              ref="multipleTable"
              :data="tableData"
              style="width: 870px; font-size: 12px"
              border
              @selection-change="handleSelectionChange"
              :header-cell-style="{
                'text-align': 'center',
                height: '32px',
                color: '#000',
                background:'#f4f4f4'
              }"
              :cell-style="{
                'text-align': 'center',
                height: '32px',
                color: '#474747',
              }"
            >
              <el-table-column type="selection" width="60" label="全选">
              </el-table-column>
              <el-table-column label="姓名" width="60" prop="name">
              </el-table-column>
              <el-table-column prop="hireState" label="雇佣状态" width="100">
              </el-table-column>
              <el-table-column prop="money" label="协议报酬" width="100px">
              </el-table-column>
              <el-table-column prop="check" label="验收后报酬" width="100px">
              </el-table-column>
              <el-table-column prop="taskState" label="任务状态" width="100px">
              </el-table-column>
              <el-table-column prop="moneyState" label="薪资状态" width="100px">
              </el-table-column>
              <el-table-column prop="arbitration" width="80px">
                <template slot-scope="scope" slot="header">
                  <span :aa="scope">
                    仲裁
                    <i
                      class="iconfont icon-a-question-frontpage r"
                      style=" margin-left:5px;'"
                    >
                    </i>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="handle" label="操作" width="160px">
                <template slot-scope="{ $index, row }">
                  <el-button type="text" @click="handleRelieve($index, row)">
                    {{ row.handle.relieve }}
                  </el-button>
                  <el-button type="text" @click="handleRelieve($index, row)">
                    {{ row.handle.pause }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mb-20"></div>
          <div class="project-administration-bottom-employee-drop">
            <el-dropdown @command="handleCommand" placement="top">
              <span class="el-dropdown-link"> 批量操作 </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">解除雇佣</el-dropdown-item>
                <el-dropdown-item command="b">暂停雇佣</el-dropdown-item>
                <el-dropdown-item command="c">重新雇佣</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-pagination layout="prev, pager, next" :total="50">
            </el-pagination>
          </div>
          <div class="mb-40"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import InfoHeader from "@/views/layout/modules/hirer/infoHeader"
import CheckTabel from "@/views/layout/modules/hirer/checkTabel";
import HirerTabel from "@/views/layout/modules/hirer/hirerTabel";
import WechateDia from "@/views/layout/modules/hirer/wechateDia";
import { getPrivateInfo, listPeriodsOfTask } from '@/api/task.js';
export default {
  name: "taskInfo",
  components: {
    CheckTabel,
    HirerTabel,
    WechateDia,
    InfoHeader
  },
  mounted() {
    new Swiper(this.$refs.floor1Swiper, {
      pagination: ".swiper-pagination",
      slidesPerView: 4,
      paginationClickable: true,
      spaceBetween: 20,
      freeMode: true,
      grabCursor: true,
    });
  },
  data() {
    return {
		taskId: 0,
		task: {},
		periods: [],
      tasklistBottom: "first",
      like: 2,
      value: 5,
      text: "",
      checktab: false,
      hiretab: false,
      centerDialogVisible: false,
      chat: false,
      deleteDialogVisible: false,
      addDialogVisible: false,
      tableData: [
        {
          name: "刘小宝",
          hireState: "已雇佣",
          money: "600",
          check: "--",
          taskState: "未开始",
          moneyState: "--",
          arbitration: "--",
          handle: {
            relieve: "解除雇佣",
            pause: "暂停雇佣",
          },
        },
        {
          name: "刘小宝",
          hireState: "本期暂停",
          money: "600",
          check: "--",
          taskState: "--",
          moneyState: "--",
          arbitration: "--",
          handle: {
            relieve: "解除雇佣",
            pause: "重新雇佣",
          },
        },
        {
          name: "刘小宝",
          hireState: "已雇佣",
          money: "600",
          check: "--",
          taskState: "未开始",
          moneyState: "--",
          arbitration: "--",
          handle: {
            relieve: "解除雇佣",
            pause: "暂停雇佣",
          },
        },
      ],
      multipleSelection: [],
      currentIndex: 1,
      texts:[
         "优秀",
         "优秀",
         "优秀",
         "优秀",
         "优秀",
      ],
      reviewForm:{
          startValue:"",
          reviewFont:"",
      }
    };
  },
  created() {
  	let taskSN = this.$route.query.sn;
  	this.loadData(taskSN);
  },
  computed: {
  	showBidBox: function() {
  		return this.task.status == 1 || this.task.status == 3;
  	},
  	showWorkStationBox: function() {
  		return this.task.status >= 5;
  	}
  },
  methods: {
	  async loadData(taskSN) {
	  	let res = await getPrivateInfo({ sn: taskSN, need_period: 1 }, this.$root.current_token);
	  	if (res.statusCode == 200) {
	  		this.task = res.data
	  		this.periods = res.data.periods
	  	}
	  },

    handleCommand(command) {
      this.$message("click on item " + command);
    },
    changeShow() {
      this.isShow = !this.isShow;
      this.showContent = !this.showContent;
    },
    showButt() {
      this.show = false;
      this.hide = true;
    },
    hideButt() {
      this.show = true;
      this.hide = false;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    hideLike() {
      this.like = 2;
    },
    showLike() {
      this.like = 1;
    },
    work() {
      this.checktab = true;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changeIndex(index) {
      this.currentIndex = index;
    },
    MoveErr(){
      this.$refs.err1.style.color="#fff"
      this.$refs.err1.style.opacity="1"
    },
    LeaveErr(){
      this.$refs.err1.style.color="#fff"
      this.$refs.err1.style.opacity="0.5"
    },
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
  },
};
</script>

<style lang="less" scoped>
.tasklist-bid {
  .tasklist-administration {
    margin-top: 20px;
    .tasklist-admininstration-center {
      background: #fff;
      .person-header {
        span,
        b {
          margin-left: 10px;
        }
        b {
          color: #00a2e6;
        }
      }
      .tasklist-admininstration-bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        /deep/.el-tabs__header {
          margin-left: 40px;
        }
        .mine-service-bottom-header-right {
          margin-top: 5px;
          margin-right: 40px;
          input {
            width: 200px;
            height: 32px;
            text-indent: 10px;
            &[placeholder] {
              font-size: 12px;
              color: #b5b5b5;
            }
          }
          .icon-search {
            font-size: 16px;
            position: relative;
            right: -190px;
            top: 2px;
          }
        }
        .tasklist-admininstration-bottom-no-data {
          width: 940px;
          background-color: #fff;
          .no-data {
            width: 180px;
            margin: 0 auto;
            font-size: 18px;
            color: #575757;
            padding-top: 100px;
            padding-bottom: 240px;
          }
        }
        /deep/.el-tabs__content {
          width: 940px;
        }
        .tasklist-admininstration-bottom-font {
          width: 860px;
          margin: 0 auto;
          background-color: #fff;
          margin-left: 40px;
          .tasklist-admininstration-bottom-font1 {
            width: 860px;
            margin-top: 40px;
            padding-bottom: 20px;
            border-bottom: 1px solid #b5b5b5;
            cursor: pointer;
            .tasklist-admininstration-bottom-font1-left {
              width: 700px;
              border-right: 1px solid #b5b5b5;
              float: left;
              .tasklist-admininstration-bottom-font1-left-center {
                width: 660px;
              }
              .tasklist-admininstration-bottom-font1-start {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 12px;
                .tasklist-admininstration-bottom-font1-start-left {
                  height: 12px;
                  display: flex;
                  align-items: center;
                  ul {
                    display: flex;
                  }
                  li {
                    margin-right: 40px;
                    strong {
                      font-weight: 550;
                      font-size: 12px;
                    }
                  }
                }
                .tasklist-admininstration-bottom-font1-start-right {
                  height: 12px;
                  display: flex;
                  align-items: center;
                  span {
                    margin-top: -3px;
                    margin-left: 10px;
                  }
                }
              }
              .tasklist-admininstration-bottom-font1-info {
                margin-top: 20px;
                p {
                  width: 660px;
                  font-size: 12px;
                  color: #575757;
                  line-height: 15px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  line-height: 18px;
                }
              }
              .tasklist-admininstration-bottom-font1-left-photo {
                display: flex;
                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                  border-radius: 50%;
                }
                .tasklist-admininstration-bottom-font1-left-photo-fonts {
                  display: flex;
                  flex-wrap: wrap;
                  p {
                    height: 16px;
                    width: 600px;
                    display: flex;
                    align-items: center;
                    button {
                      padding: 0px 11px;
                      border-radius: 8px;
                      background-color: #00a2e6;
                      margin: 0 20px 0 20px;
                      font-size: 12px;
                      height: 16px;
                    }
                    img {
                      width: 16px;
                      height: 16px;
                    }
                    i {
                      display: inline-block;
                    }
                    .icon-a-PersonalIdentity,
                    .icon-phone {
                      color: #1296db;
                      margin-right: 10px;
                      font-size: 16px;
                    }
                    .icon-like {
                      font-size: 24px;
                      position: relative;
                      right: -235px;
                      top: 2px;
                      color: #d4237a;
                      cursor: pointer;
                    }
                    .no {
                      color: #dbdbdb;
                    }
                  }
                  span {
                    position: relative;
                    top: 5px;
                  }
                }
              }
              .tasklist-admininstration-bottom-font1-task {
                margin-top: 20px;
                ul {
                  display: flex;
                  li {
                    font-size: 12px;
                    font-weight: 550;
                    &:not(li:last-of-type)::after {
                      content: "/";
                      margin: 0 10px;
                    }
                  }
                }
              }
            }

            .tasklist-admininstration-bottom-font1-right {
              width: 159px;
              float: right;
              .tasklist-admininstration-bottom-font1-right-center {
                width: 120px;
                margin-left: 39px;
                text-align: center;
                i {
                  margin-top: 28px;
                  display: inline-block;
                  font-size: 34px;
                  color: #32c864;
                }
                strong {
                  display: block;
                  margin-top: 10px;
                  font-size: 16px;
                  color: #32c864;
                  margin-bottom: 20px;
                }
                button {
                  text-align: center;
                  width: 120px;
                  height: 24px;
                }
                .select {
                  background-color: #00a2e6;
                }
                .noSelect {
                  color: #575757;
                  border: 1px solid #b5b5b5;
                  background-color: #fff;
                }
              }
            }
          }
          .tasklist-admininstration-bottom-font1:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
  .project-administration {
    margin-top: 20px;
    background-color: #fff;
    .project-administration-bottom {
      width: 860px;
      margin: 0 auto;
      padding-bottom: 60px;
      .project-administration-bottom-box {
        width: 860px;
        height: 160px;
        border: 1px solid black;
        display: flex;
        .project-administration-bottom-box-center {
          width: 880px;
          display: flex;
          justify-content: space-between;
          .project-administration-bottom-box1-left,
          .project-administration-bottom-box1-right {
            width: 90px;
            height: 160px;
            background: rgba(244, 244, 244, 0.8);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-arrow-large-Left,
            .icon-arrow-large-right {
              display: block;
              font-size: 48px;
            }
          }
        }
        .project-administration-bottom-box1-center {
          display: flex;
        }
        .project-administration-bottom-box1 {
          width: 200px;
          height: 160px;
          background-color: #f4f4f4;
          border-radius: 10px;
          margin-right: 20px;
          position: relative;
          .project-administration-bottom-box1-header,
          .project-administration-bottom-box1-footer {
            width: 160px;
            margin: 0 auto;
          }
          .project-administration-bottom-box1-header {
            border-bottom: 1px solid #b5b5b5;
          }

          .project-administration-bottom-box1-center1 {
            text-align: center;
            width: 58px;
            height: 65px;
            margin: 0 auto;
            position: relative;
            top: 48px;
            border: 1px solid red;
            cursor: pointer;
            .icon-a-addto {
              font-size: 32px;
              color: #00a2e6;
            }
            span {
              font-size: 14px;
            }
            b {
              position: absolute;
              right: 10px;
              top: 10px;
              color: #00a2e6;
              background-color: rgba(244, 244, 244, 0.5);
            }
          }
        }
      }
      .project-administration-bottom-dialog {
        width: 600px;
        height: 330px;
        background-color: #fff;
        border: 1px solid red;
        position: relative;
        z-index: 3;
        margin-top: -100px;
      }

      .deleteDialog,
      .addDialog {
        .deleteDialog-center {
          width: 520px;
          margin: 0 auto;
          .deleteDialog-center-header {
            border-bottom: 1px solid #b5b5b5;
          }
          .deleteDialog-center-header-table {
            border-bottom: 1px solid #b5b5b5;
            ul {
              height: 50px;
              display: flex;
              align-items: center;
              .deleteDialog-li1 {
                width: 120px;
                text-align: center;
              }
              .deleteDialog-li2 {
                width: 200px;
                text-align: center;
              }
              li {
                border-right: 1px solid #b5b5b5;
                line-height: 30px;
              }
              li:last-of-type {
                border-right: none;
              }
            }
            .deleteDialog-center-header-table-header {
              width: 520px;
              height: 50px;
              background: #f8f8f8;
            }
            .deleteDialog-center-header-table-bottom {
              height: 50px;
              li {
                border-right: 0;
              }
            }
          }
          .deleteDialog-center-checked-button {
            margin: 0 auto;
            width: 360px;
            height: 24px;
            button {
              width: 160px;
              height: 24px;
              background-color: #00a2e6;
              font-size: 12px;
            }
            .cancel {
              background-color: #b5b5b5;
            }
          }
        }
      }
      .addDialog {
        .deleteDialog-center-header {
          span {
            color: #00a2e6;
          }
        }
      }
    }
    .project-administration-bottom-employee {
      width: 862px;
      margin: 0 auto;
      border-top: 1px solid #b5b5b5;
      .project-administration-bottom-employee-drop {
        display: flex;
        height: 14px;
        justify-content: space-between;
      }
      .project-administration-bottom-employee-tabel{
        /deep/.el-table .cell{
          text-overflow:clip ;
          overflow: none;
        }
      }
      /deep/.el-pagination button,
      /deep/.el-pagination span:not([class*="suffix"]) {
        height: 14px;
      }
      /deep/.el-pager li {
        height: 14px;
        line-height: 14px;
        /* color: #575757; */
      }
    }
  }
}
span {
  color: #575757;
  font-size: 12px;
}
img {
  width: 16px;
  height: 16px;
}
i {
  font-size: 12px;
}


.box .el-tabs__active-bar.is-top {
  text-align: center;
  width: 0px !important; /* 不加important  不会生效，important会覆盖行内样式权限级别*/
  padding: 0 50px !important; /* 控制位置*/
  left: 8.5%; /* 根据实际情设置，尽量居中就行*/
  box-sizing: border-box;
  background-clip: content-box;
}
/deep/#tab-first,
/deep/#tab-second,
/deep/#tab-third {
  width: 120px;
  text-align: center;
}
/deep/.drop {
  position: relative;
  left: 450px;
}
// dialog的修改
.dialog-center-header {
  width: 520px;
  margin: 0 auto;
  border-bottom: 1px solid #b5b5b5;
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.dialog-footer {
  button {
    width: 160px;
    height: 36px;
    background-color: #00a2e6;
  }
  .error {
    background-color: #b5b5b5;
  }
  .dialog-button {
    margin-right: 40px;
  }
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog--center /deep/ .el-dialog__body {
  padding: 0;
}
/deep/.el-tabs__item {
  padding: 0;
}
/deep/.el-tabs {
  width: 650px;
}
/deep/.el-tabs__content {
  width: 860px;
  margin: 0 auto;
}
.project-administration-bottom-box1 {
  width: 200px;
  height: 160px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
  .project-administration-bottom-box1-header,
  .project-administration-bottom-box1-footer {
    width: 160px;
    margin: 0 auto;
  }
  .project-administration-bottom-box1-header {
    border-bottom: 1px solid #b5b5b5;
  }
  .project-administration-bottom-box1-header-img,
.project-administration-bottom-box1-footer
{
   text-align: center;
}
  .project-administration-bottom-box1-center1 {
    text-align: center;
    width: 58px;
    height: 65px;
    margin: 0 auto;
    cursor: pointer;
    .icon-a-addto {
      display: inline-block;
      margin-top: 48px;
      font-size: 32px;
      color: #00a2e6;
    }
    span {
      font-size: 14px;
    }
    b {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #00a2e6;
      background-color: rgba(244, 244, 244, 0.5);
    }
  }
  .project-administration-bottom-box1-err {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    opacity: 0.5;
  }
}
/* 设置tabel的样式 */

/deep/.el-table,
/deep/ .el-table__cell {
  padding: 0 !important;
}
/deep/.el-button--text {
  font-size: 12px;
  color: #575757;
}
/deep/.el-button--text:hover {
  color: #00a2e6;
}
.hoverBlue {
  background-color: #00a2e6;
}
.hoverff{
  color: #fff;
}
.borderff{
  border-bottom:1px solid #fff !important;
}
/deep/.el-tabs__content {
  width: 860px;
  margin: 0 auto;
}
/deep/#pane-forst,
/deep/#pane-second,
/deep/#pane-third {
  width: 860px;
  margin: 0 auto;
}
.block {
  background-color: #fff;
  width: 430px;
  margin:  0 auto;
  margin-top: 40px;
  padding-bottom: 60px;
}
/deep/.el-table__header
.el-table-column--selection
.cell .el-checkbox:after {
  color: #000;
  display: inline-block;
  content: "全选";
  font-size: 12px;
  margin-left: 5px;
}


//评论板块的样式
.task-info-review{
  background-color: #fff;
  .task-info-reviews-all{
    padding: 40px;
    display: flex;
    justify-content: space-between;
    .task-info-reviews-all-item{
      width: 280px;
      text-align: center;
      span{
        font-size: 14px;
        color: #575757;
      }
      p{
        padding-top: 30px;
        padding-bottom: 20px;
        strong{
          font-size: 36px;
        }
        span{
          font-size: 14px;
        }
      }
    }
  }
  .task-info-review-first{
    padding: 20px 40px 40px 40px;
    .task-info-review-first-center{

    }
    .task-info-review-first-success{
      p{
        line-height: 22px;
        padding-bottom: 40px;
      }
    }
  }
}
.task-info-review-more{
  .person-header{
    p{
      width: 100%;
      height: 20px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-right: 40px;
    }

  }
}
</style>
